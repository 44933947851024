(function($, window, document, undefined) {

    'use strict';

    $(function() {

        $(document).foundation();

        var deadline = 'Oct 7 2017 18:00:00 GMT-0400';

        function time_remaining(endtime) {
            var t = Date.parse(endtime) - Date.parse(new Date());
            var seconds = Math.floor((t / 1000) % 60);
            var minutes = Math.floor((t / 1000 / 60) % 60);
            var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
            var days = Math.floor(t / (1000 * 60 * 60 * 24));
            return {
                'total': t,
                'days': days,
                'hours': hours,
                'minutes': minutes,
                'seconds': seconds
            };
        }

        function run_clock(id, endtime) {
            var clock = document.getElementById(id);

            // get spans where our clock numbers are held
            var days_span = clock.querySelector('.days');
            var hours_span = clock.querySelector('.hours');
            var minutes_span = clock.querySelector('.minutes');
            var seconds_span = clock.querySelector('.seconds');

            function update_clock() {
                var t = time_remaining(endtime);

                // update the numbers in each part of the clock
                days_span.innerHTML = t.days;
                hours_span.innerHTML = ('0' + t.hours).slice(-2);
                minutes_span.innerHTML = ('0' + t.minutes).slice(-2);
                seconds_span.innerHTML = ('0' + t.seconds).slice(-2);

                if (t.total <= 0) {
                    clearInterval(timeinterval);
                }
            }
            update_clock();
            var timeinterval = setInterval(update_clock, 1000);
        }
        run_clock('clockdiv', deadline);
    });

    $('nav a').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000); // The number here represents the speed of the scroll in milliseconds
                return false;
            }
        }
    });
    
    $("#contactForm").submit(function(event){
	    // cancels the form submission
	    event.preventDefault();
			 submitForm();
	});
	function submitForm(){
	    // Initiate Variables With Form Content
	    var name = $("#name").val();
	    var email = $("#email").val();
	    var message = $("#message").val();
	 
	    $.ajax({
		        type: "POST",
		        url: "submitform.php",
		        data: "name=" + name + "&email=" + email + "&message=" + message,
		        success : function(text){
		            if (text == "success"){
		                formSuccess();
		            }
		        }
		    });
	}
	function formSuccess(){
	    $( "#msgSubmit" ).removeClass( "hide" );
	}




})(jQuery, window, document);